<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ !item.id ? 'Adding a new email account' : 'Email account editing' }}</div>
    </template>
    <div class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="email_account_username" :class="{'p-invalid' : submitted && (!accountData.username || !emailIsValid)}" v-model.trim="accountData.username" @input="validateEmail($event.target.value)" autocomplete="new-password"/>
          <label for="email_account_username">{{ $t('Email') }}<span class="warning-color">*</span></label>
        </div>
<!--        <small class="p-invalid" v-if="submitted && !accountData.username">{{ $t('Required field') }}</small>-->
        <small class="p-invalid" v-if="submitted && !accountData.username">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-else-if="submitted && accountData.username && !emailIsValid">{{ $t('Value is not a valid email address') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <Password id="email_account_password" :inputClass="submitted && !accountData.password ? 'p-invalid' : ''" v-model.trim="accountData.password" toggleMask autocomplete="new-password"/>
          <label for="email_account_password">{{ $t('Password') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !accountData.password">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="email_account_sender_name" v-model.trim="accountData.sender_name" autocomplete="new-password"/>
          <label for="email_account_sender_name">Senders name</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="email_account_connection_type"
                              :class="{'p-invalid' : submitted && !selectedEncryptionType}"
                              v-model="selectedEncryptionType"
                              :options="encryptionTypes"
                              disabled
                              :filter="false"
                              optionLabel="value"
                              :showClear="false">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.value }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.value }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="email_account_connection_type">Encryption<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedEncryptionType">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="email_account_imap_server" :class="{'p-invalid' : submitted && !accountData.imap_server}" v-model.trim="accountData.imap_server" autocomplete="new-password"/>
          <label for="email_account_imap_server">IMAP server<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !accountData.imap_server">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber :locale="computedNumberInputLocale" id="email_account_imap_port" :format="false" :inputClass="{'p-invalid' : submitted && !accountData.imap_port}" showButtons v-model="accountData.imap_port" autocomplete="new-password"/>
          <label for="email_account_imap_port">IMAP port<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !accountData.imap_port">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="email_account_smtp_server" :class="{'p-invalid' : submitted && !accountData.smtp_server}" v-model.trim="accountData.smtp_server" autocomplete="new-password"/>
          <label for="email_account_smtp_server">SMTP server<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !accountData.smtp_server">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber :locale="computedNumberInputLocale" id="email_account_smtp_port" :format="false" :inputClass="{'p-invalid' : submitted && !accountData.smtp_port}" showButtons v-model="accountData.smtp_port" autocomplete="new-password"/>
          <label for="email_account_smtp_port">SMTP port<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !accountData.smtp_port">{{ $t('Required field') }}</small>
      </div>
<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">-->
<!--        <div class="p-float-label">-->
<!--          <Textarea id="email_account_default_signature" v-model.trim="accountData.signature" rows="5" :autoResize="true" autocomplete="off"/>-->
<!--          <label for="email_account_default_signature">Default</label>-->
<!--        </div>-->
<!--      </div>-->
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-d-flex p-ai-center">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="is_default_for_offers" v-model="accountData.is_default_for_offers"/>
          </div>
          <label for="is_default_for_offers" class="switch-label pointer">Default for offers</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-d-flex p-ai-center">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="is_default_for_invoices" v-model="accountData.is_default_for_invoices"/>
          </div>
          <label for="is_default_for_invoices" class="switch-label pointer">Default for sales invoices</label>
        </div>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-d-flex p-ai-center">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="auto_receive_emails" v-model="accountData.auto_receive_emails"/>
          </div>
          <label for="auto_receive_emails" class="switch-label pointer">Receive messages automatically</label>
        </div>
      </div>

<!--      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">-->
<!--        <div class="p-d-flex p-ai-center">-->
<!--          <InputSwitch id="email_account_is_master" v-model="accountData.is_master"/>-->
<!--          <label for="email_account_is_master" class="switch-label pointer">Is master</label>-->
<!--        </div>-->
<!--      </div>-->
    </div>


    <div v-if="accountData?.id">
      <div>{{ $t('Users') }}:</div>
      <div>
        <AddNewButton :disabled=" emailUsers.length >= employees.length" class="p-mt-2" style="width: auto;" :label="$t('Add')" @click="addUserAccess" />
      </div>
      <div class="p-mt-3 p-pb-3 table-wrapper" v-if="emailUsers.length">
        <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows users-table">
          <thead class="p-datatable-thead">
          <tr>
            <th>{{ $t('User') }}</th>
            <th>Show messages</th>
            <th>Signature</th>
            <!--            <th class="table-title__invoice"></th>-->
            <th style="width: 96px"></th>
          </tr>
          </thead>
          <tbody class="p-datatable-tbody">
          <tr v-for="(user, index) of emailUsers"
              :key="index"
              :class="{'updated-item': isItemUpdated(user.id)}">
            <td>
              <!--                <div><i class="ti-user p-mr-2"></i>{{ user.userData.first_name }} {{ user.userData.last_name }}</div>-->
              <div><i class="ti-user p-mr-2"></i>{{ user.first_name }} {{ user.last_name }}</div>
            </td>
            <td>
              {{ user.show_messages ? $t('Yes') : $t('No')}}
            </td>
            <td>
              <div v-if="user.signature">
                <span style="font-size: 1.2em" @click.prevent href="" v-tooltip.top="user.signature"><i class="ti-info-alt" ></i></span>
              </div>
            </td>
            <td>
              <EditButton @click="editUserAccess(user)"></EditButton>
              <DeleteButton @click="confirmDeleteUserAccess(user)"></DeleteButton>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <i>To add users, please save a new account first.</i>
    </div>

<!--    <div v-else style="padding: 10px 0; text-align: center">No users</div>-->

<!--    <Toolbar class="p-mb-4">-->
<!--      <template v-slot:left>-->
<!--        <AddNewButton :label="$t('Add')" @click="addNewItem" />-->
<!--      </template>-->
<!--      <template v-slot:right>-->
<!--        &lt;!&ndash;            <router-link :to="{ path: `/customers-archive` }">&ndash;&gt;-->
<!--        &lt;!&ndash;              <Button label="Archive" icon="ti-archive" class="p-button-info p-button-raised p-mr-2"/>&ndash;&gt;-->
<!--        &lt;!&ndash;            </router-link>&ndash;&gt;-->
<!--      </template>-->
<!--    </Toolbar>-->


<!--    <DesktopDataTable :tableName="$t('Users')"-->
<!--                      :itemsArrayIsEmpty="true"-->
<!--                      :showSearchInput="false"-->
<!--                      :actionButtonsCount="2"-->
<!--                      :headers="[-->
<!--              {name: 'name', title: 'Name', width: 'auto', sortable: false},-->
<!--          ]">-->
<!--      <template v-slot:body>-->
<!--        <tr v-for="(user, index) of accountData.emailUsers" :key="index">-->
<!--          <td>-->
<!--            <span>{{ user.userData.first_name }} {{ user.userData.last_name }}</span>-->
<!--          </td>-->
<!--          <td>-->
<!--            <div class="table__actions-column-data">-->
<!--              <EditButton></EditButton>-->
<!--              <DeleteButton></DeleteButton>-->
<!--            </div>-->
<!--          </td>-->
<!--        </tr>-->
<!--      </template>-->
<!--    </DesktopDataTable>-->

    <EmailAccountUserModal :visible="emailUserModal"
                           :employees="employeesData"
                           :item="emailUser"
                           :emailAccountId="accountData.id"
                           @close="closeEmailUserModal"
                           @update-items="updateItems"
                           @update-item="updateItem"/>

    <ConfirmDeleteModal
        :visible="confirmDeleteUserAccessModal"
        @close="closeConfirmDeleteUserAccessModal"
        @delete-item="deleteUserAccess">
    </ConfirmDeleteModal>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import EmailAccountUserModal from "@/pages/settings/components/EmailAccountUserModal";
import ConfirmDeleteModal from "@/components/ConfirmDeleteModal";
import httpMixins from "@/mixins/httpMixins";
import formatMixins from '@/mixins/formatMixins';

export default {
  mixins: [ httpMixins, formatMixins ],
  components: { EmailAccountUserModal, ConfirmDeleteModal },
  emits: ['close', 'update-items'],
  name: 'EmailAccountModal',
  props: {
    item: Object,
    employees: Array,
    visible: Boolean,
  },
  data() {
    return {
      // isDefaultForInvoices: false,
      // isDefaultForOffers: false,
      employeesData: [],
      emailUserToDelete: {},
      confirmDeleteUserAccessModal: false,
      updatedItemId: null,
      accountData: {},
      emailUsers: [],
      emailUser: {},
      emailUserModal: false,
      emailIsValid: false,
      submitted: false,
      selectedEncryptionType: null,
      encryptionTypes: [{value: 'TLS'}],
    }
  },
  watch: {
    // employees(value) {
    //   this.employeesData = [...value]
    //   // console.log(value)
    // },
    item(value) {
      if (!value) return false
      this.accountData = {
        ...value,
        is_default_for_offers: !!value.is_default_for_offers,
        is_default_for_invoices: !!value.is_default_for_invoices,
        auto_receive_emails: !!value.auto_receive_emails,
        // is_default_for_notifications: !!value.is_default_for_notifications,
        // is_master: !!value.is_master,
      }
      if (!value.imap_port) {
        this.accountData.imap_port = 993
      }
      if (!value.smtp_port) {
        this.accountData.smtp_port = 587
      }

      if (!value.sender_name) {
        this.accountData.sender_name = this.$store.state.branchData?.name ? this.$store.state.branchData?.name : ''
      }

      if (value.connection_type) {
        this.selectedEncryptionType = { value: value.connection_type }
      } else {
        this.selectedEncryptionType = this.encryptionTypes[0]
      }

      // this.isDefaultForOffers = !!this.item.is_default_for_offers
      // this.isDefaultForInvoices = !!this.item.is_default_for_invoices

      if (value.emailUsers) {
        this.emailUsers = value.emailUsers.map(user => ({
              emailUserId: user.id,
              ...user.userData,
              show_messages: user.show_messages,
              signature: user.signature
            }
        ))
      }

      if (value.username) {
        this.validateEmail(value.username)
      }
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.accountData = {}
        this.selectedEncryptionType = null
        this.emailUsers = []


        this.employeesData = []
        this.emailUserToDelete = {}
        this.updatedItemId = null
        this.emailUser = {}
      }
    },
    '$store.state.secondLayerIsOpened'() {
      if (!this.$store.state.secondLayerIsOpened) {
        if (this.emailUserModal) {
          this.closeEmailUserModal()
        }
        if (this.confirmDeleteUserAccessModal) {
          this.closeConfirmDeleteUserAccessModal()
        }
      }
    },
  },
  methods: {
    updateItems(id) {
      this.$emit('update-items', id)
    },
    updateItem(user) {
      // console.log(user.show_messages)
      if (user.isNew) {
        this.emailUsers.push(user)
      } else {
        let foundUser = this.emailUsers.filter(u => u.emailUserId === user.emailUserId)[0]
        if (foundUser) {
          // foundUser.updatedUser = user.updatedUser
          foundUser.id = user.id
          foundUser.first_name = user.first_name
          foundUser.last_name = user.last_name
          foundUser.show_messages = user.show_messages
          foundUser.signature = user.signature
        }
      }

      if (user.id) {
        this.updatedItemId = user.id
      }
    },
    addUserAccess() {
      this.emailUser = {}
      this.emailUserModal = true
      this.$store.commit('toggleSecondLayer', true)
      this.generateEmployeesData()
    },
    editUserAccess(user) {
      // console.log(user)
      this.emailUser = user
      this.emailUserModal = true
      this.$store.commit('toggleSecondLayer', true)
      this.generateEmployeesData()
    },
    generateEmployeesData() {
      const userIdsSet = new Set()
      this.emailUsers.forEach(u => {
        userIdsSet.add(u.id)
      })
      const userIdsArray = [...userIdsSet]
      this.employeesData = []

      this.employees.forEach(e => {
        if (userIdsArray.indexOf(e.id) === -1 || e.id === this.emailUser?.id) {
          this.employeesData.push(e)
        }
      })
    },
    confirmDeleteUserAccess(user) {
      this.emailUserToDelete = user
      this.confirmDeleteUserAccessModal = true
      this.$store.commit('toggleSecondLayer', true)
    },
    closeConfirmDeleteUserAccessModal() {
      this.emailUserToDelete = {}
      this.confirmDeleteUserAccessModal = false
      this.$store.commit('toggleSecondLayer', false)
    },
    async deleteUserAccess() {
      try {
        const { status } = await httpClient.post('mail/delete-email-user', { emailUserId: this.emailUserToDelete.emailUserId })
        if (status === 204) {
          this.emailUsers = this.emailUsers.filter(u => u.emailUserId !== this.emailUserToDelete.emailUserId)
          this.$emit('update-items', this.accountData.id)
          this.emailUserToDelete = {}
          this.closeConfirmDeleteUserAccessModal()
        }
      } catch(err) {
        this.showError(err)
      }
    },
    closeEmailUserModal() {
      this.emailUser = {}
      this.emailUserModal = false
      this.$store.commit('toggleSecondLayer', false)
    },
    async saveItem() {
      this.submitted = true
      if (!this.accountData.username || !this.emailIsValid || !this.accountData.password || !this.selectedEncryptionType || !this.accountData.imap_server || !this.accountData.imap_port || !this.accountData.smtp_server || !this.accountData.smtp_port) {
        return false
      }

      const emailAccount = {
        id: this.accountData.id ?? null,
        ...this.accountData,
        encryption: this.selectedEncryptionType ? this.selectedEncryptionType.value : null,
        // is_default_for_offers: this.isDefaultForOffers,
        // is_default_for_invoices: this.isDefaultForInvoices
      }

      // console.log(emailAccount)

      if (this.accountData.id) {
        try {
          const { status, data } = await httpClient.post(`mail/update-account`, emailAccount)
          console.log(data)
          if (status === 200) {
            this.$emit('update-items', this.accountData.id)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: 1750});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`mail/create-account`, emailAccount)
          if (status === 201 && data) {
            this.accountData.id = data
            emailAccount.id = data
            this.$emit('update-items', this.accountData.id)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: 1750});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      }
    },
    isItemUpdated(itemId) {
      return this.updatedItemId === itemId
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (!isCompany) {
      this.emailIsValid = re.test(email)
      // } else {
      //   this.companyEmailIsValid = re.test(email)
      // }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">
.table-wrapper {
  overflow-y: auto;
}

.users-table {
  border-collapse: collapse;
  width: 100%;
  .users-table__name {
    //width: 90%;
    //width: auto;
  }
  .users-table__buttons {
    //width: 100%;
  }
}
</style>