//<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Email accounts') }}</div>
            <AddNewButton :label="$t('Add')" @click="addNewItem" />
          </template>
<!--          <template #end>-->
<!--            <div class="p-input-icon-left">-->
<!--              <i class="pi pi-search" />-->
<!--              <InputText @input="searchItems($event.target.value, false)" v-model="searchData" :placeholder="$t('Search') + '...'" />-->
<!--            </div>-->
<!--          </template>-->
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else
                          :showHeader="false"
                          :tableName="$t('Email accounts')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
              {name: 'username', title: 'Username',sortable: true},
              {name: 'sender_name', title: 'Sender name', sortable: true},
              {name: 'imap_server', title: 'IMAP server', sortable: true},
              {name: 'imap_port', title: 'IMAP port', sortable: true},
              {name: 'smtp_server', title: 'SMTP server', sortable: true},
              {name: 'smtp_port', title: 'SMTP port', sortable: true},
              {name: 'is_default_for_offers', title: 'Offers default', sortable: true},
              {name: 'is_default_for_invoices', title: 'Invoices default', sortable: true},
              {name: 'auto_receive_emails', title: 'Auto receive', sortable: true},
              {name: 'access', title: 'Access', sortable: false},
              // {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td>
                <span v-if="item.username">{{ item.username }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.sender_name">{{ item.sender_name }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.imap_server">{{ item.imap_server }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.imap_port">{{ item.imap_port }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.smtp_server">{{ item.smtp_server }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.smtp_port">{{ item.smtp_port }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>{{ $t(item.is_default_for_offers ? 'Yes' : 'No') }}</td>
              <td>{{ $t(item.is_default_for_invoices ? 'Yes' : 'No') }}</td>
              <td>{{ $t(item.auto_receive_emails ? 'Yes' : 'No') }}</td>
              <td>
<!--                <span v-if="item.emailUsers">-->
<!--                  <span v-for="(user, index) of item.emailUsers" :key="index">-->
<!--                    <span v-if="user.userData">-->
<!--                      {{ user.userData.first_name }} {{ user.userData.last_name }}<span v-if="item.emailUsers.length > 1 && index !== item.emailUsers.length - 1">, </span>-->
<!--                    </span>-->
<!--                  </span>-->
<!--                </span>-->
                <div v-if="item.emailUsers?.length" class="p-ml-1 p-badge badge"
                     v-tooltip.top="createTooltipData(item)">{{ item.emailUsers.length }}
                </div>
                <div v-else class="p-ml-1 p-badge badge">0</div>
              </td>
              <td>
                <div class="p-d-flex">
                  <EditButton :disabled="item.role === constants.userRoles.superAdmin && $store.state.user.role !== constants.userRoles.superAdmin"
                              @click="editItem(item)" />
                  <DeleteButton @click="confirmItemDelete(item)"
                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <!--  Mobile DataTable -->
<!--        <MobileDataTable v-else-if="!isLoading && $store.state.tabletLayout"-->
<!--                         :tableName="$t('Taxes')"-->
<!--                         :showSearchInput="false"-->
<!--                         :itemsArrayIsEmpty="itemsArrayIsEmpty">-->
<!--          <template v-slot:body>-->
<!--            <tr :class="{'updated-item': isItemUpdated(item.id)}"-->
<!--                v-for="(item, index) of items"-->
<!--                :key="index">-->
<!--              <td>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center p-pt-2">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Name') }}:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span>-->
<!--                      <div>{{ item.name  || '' }}</div>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Value:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">{{ item.value || '' }}</div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Is default:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.is_default">{{ $t('Yes') }}</span>-->
<!--                    <span v-else>{{ $t('No') }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-m-2 p-d-flex p-jc-center">-->
<!--                  <EditButton @click="editItem(item)" />-->
<!--                  <DeleteButton @click="confirmItemDelete(item)"-->
<!--                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->
<!--        </MobileDataTable>-->

        <OwnPaginator v-show="items && items.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

        <EmailAccountModal :visible="itemModal"
                     :item="item"
                     :employees="employees"
                     @update-items="updateItems"
                     @close="closeItemModal">
        </EmailAccountModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import constants from '@/constants'
import EmailAccountModal from "@/pages/settings/components/EmailAccountModal";
import formatMixins from "@/mixins/formatMixins";
import datatableMixins from "@/mixins/datatableMixins";
import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";

export default {
  mixins: [ formatMixins, datatableMixins, httpMixins ],
  components: { EmailAccountModal },
	data() {
		return {
      employees: [],
      fieldsExpand: 'emailUsers',
      apiLink: 'mail/get-accounts',
      routerPath: '/email-accounts',
      searchData: '',
      sort: {
        column: 'id',
        direction: '-',
      },
		}
	},
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
    this.getEmployees()
	},
  methods: {
    createTooltipData(item) {
      if (!item.emailUsers?.length) {
        return false
      }
      const emailUsers = item.emailUsers
      let html = ''
      emailUsers.forEach(user => {
        html += `<div>${user.userData?.first_name} ${user.userData?.last_name}</div>`
      })

      return html
    },
    async getItems() {
      try {
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.searchData,
            expand: this.fieldsExpand,
          },
        })

        // const { status, data, headers } = await httpClient(`${this.apiLink}?page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']

          this.items = data
          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      }
      this.isLoading = false
    },
	},
  computed: {
    actionButtonsCount() {
      if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
        return 2
      } else {
        return 1
      }
    },
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>

<style scoped lang="scss">

</style>