<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ item?.id ? 'Email access editing' : 'Adding a new email access' }}</div>
    </template>
    <div class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="email_account_user_signature"
                              :class="{'p-invalid' : submitted && !selectedUser}"
                              v-model="selectedUser"
                              :options="employees"
                          :clearSearchData="!visible"
                              :filter="true"
                              :filterFields="['first_name', 'last_name']"
                              :showClear="false">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.first_name }} {{ slotProps.value.last_name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.first_name }} {{ slotProps.option.last_name }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="email_account_user_signature">{{ $t('User') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedUser">{{ $t('Required field') }}</small>
      </div>

      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <Textarea id="email_account_user_signature" v-model.trim="emailUserData.signature" rows="6" :autoResize="true" autocomplete="off"/>
          <label for="email_account_user_signature">Signature</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <div class="p-d-flex p-ai-center">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="email_account_user_show_messages" v-model="showMessages"/>
          </div>
          <label for="email_account_user_show_messages" class="switch-label pointer">Show messages?</label>
        </div>
      </div>
    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ httpMixins ],
  emits: ['close', 'update-items', 'update-item'],
  name: 'EmailAccountUserModal',
  props: {
    emailAccountId: Number,
    item: Object,
    employees: Array,
    visible: Boolean,
  },
  data() {
    return {
      showMessages: true,
      emailUserData: {},
      submitted: false,
      selectedUser: null,
    }
  },
  watch: {
    item(value) {
      if (!value) return false
      this.emailUserData = { ...value }
      this.showMessages = !!value.show_messages

      if (value.id) {
        this.selectedUser = {
          id: value.id,
          first_name: value.first_name,
          last_name: value.last_name
        }
      }
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        // this.emailUserData = {}
        this.selectedUser = null
      }
    },
  },
  methods: {
    async saveItem() {
      this.submitted = true
      if (!this.selectedUser) {
        return false
      }

      const emailUser = {
        emailUserId: this.emailUserData.emailUserId,
        // id: this.emailUserData.id,
        // user_id: this.selectedUser?.id,
        id: this.selectedUser?.id,
        email_account_id: this.emailAccountId,
        show_messages: this.showMessages,
        signature: this.emailUserData.signature
      }


      if (this.emailUserData.id) {
        // emailAccount.id = this.emailUserData.id
        try {
          const { status } = await httpClient.post(`mail/update-email-user`, emailUser)
          if (status === 200) {
            emailUser.first_name = this.selectedUser.first_name
            emailUser.last_name = this.selectedUser.last_name

            this.$emit('update-items', emailUser.email_account_id)
            this.$emit('update-item', emailUser)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: 1750});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`mail/create-email-user`, emailUser)
          if (status === 201 && data) {
            emailUser.emailUserId = data
            emailUser.first_name = this.selectedUser.first_name
            emailUser.last_name = this.selectedUser.last_name
            emailUser.isNew = true

            this.$emit('update-items', emailUser.email_account_id)
            this.$emit('update-item', emailUser)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: 1750});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        }
      }

    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    // computedWindowSize() {
    //   if (this.$store.state.mobileLayout) {
    //     return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
    //   } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
    //     return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
    //   } else {
    //     return {position: 'absolute', top: 0, width: '550px'}
    //   }
    // },
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '85%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '450px', marginTop: '25px'}
      } else {
        return {position: 'relative', top: '-10vh', width: '450px', marginTop: '50px'}
      }
    }
  },

}
</script>

<style scoped lang="scss">
</style>